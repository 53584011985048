import React from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Translate from "../Translator/Translate";
import { useTranslator } from "../../utilities/hooks/useTranslator";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { Form, Formik } from "formik";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Close from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { debounce } from "lodash";
import MenuItem from "@material-ui/core/MenuItem";
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import Skeleton from "@material-ui/lab/Skeleton";
import NoDataToShow from "../../utilities/no-data-to-show";
import { getTranslatedLabels } from "../../utils";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useLocale } from "../../utilities/hooks/useLocale";
import FormLabel from "../form/form-label";
import { InputLabel } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";

const CONDITIONAL_KEY = "__CONDITION";
const conditions = [
  { label: "label.filters.conditions.equal", value: "EQUAL" },
  { label: "label.filters.conditions.greaterThan", value: "GREATER" },
  {
    label: "label.filters.conditions.greaterThanEqual",
    value: "GREATER_EQUAL",
  },
  { label: "label.filters.conditions.lessThan", value: "LESS" },
  { label: "label.filters.conditions.lessThanEqual", value: "LESS_EQUAL" },
  { label: "label.filters.conditions.notEqual", value: "NOT_EQUAL" },
];

const useStyle = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
  },
  buttonRoot: {
    padding: theme.spacing(0.5, 1),
    display: "flex",
    marginRight: theme.spacing(1),
    "&:hover": {
      cursor: "initial",
    },
  },
  font: {
    "& .MuiInputBase-input": {
      fontSize: 13,
    },
  },
  endIcon: {
    marginRight: 0,
    marginTop: 0,
    alignSelf: "flex-start",
    "& svg": {
      fontSize: 26,
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  panelDetails: {
    padding: theme.spacing(2),
  },
  expansionPanel: {
    margin: theme.spacing(0),
    "& .MuiExpansionPanelSummary-root": {
      borderTopLeftRadius: 2,
      borderTopRightRadius: 2,
    },
    "& .MuiExpansionPanelDetails-root": {
      borderBottomLeftRadius: 2,
      borderBottomRightRadius: 2,
    },
  },
  panelTitle: {
    textTransform: "uppercase",
    fontWeight: theme.typography.fontWeightBold,
  },
  filterBadges: {
    marginTop: theme.spacing(-0.5),
    marginBottom: theme.spacing(-0.5),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    fontWeight: theme.typography.fontWeightBold,
  },
  formControlRoot: {
    marginTop: 0,
  },
  formFieldWrapper: {
    [theme.breakpoints.up("xs")]: {
      margin: theme.spacing(0, 0),
    },
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(0, 0.5),
    },
    [theme.breakpoints.up("lg")]: {
      margin: theme.spacing(0, 1),
    },
  },
  checkboxField: {
    width: theme.spacing(30),
    marginTop: 15,
    "& .MuiIconButton-label": {
      "& svg": {
        fontSize: 26,
      },
      width: 26,
      height: 26,
    },
  },
}));

const getSavedFilters = (key) => {
  let savedFilters = sessionStorage.getItem("filters");
  if (savedFilters) {
    savedFilters = JSON.parse(savedFilters);
  } else {
    savedFilters = {};
  }
  if (key) return savedFilters[key] ? { ...savedFilters[key] } : null;
  return savedFilters;
};

const putSavedFilters = (key, filters) => {
  const savedFilters = getSavedFilters();
  savedFilters[key] = filters;
  sessionStorage.setItem("filters", JSON.stringify(savedFilters));
};

const reducer = (state, newState) => {
  let newFilters = null;
  if (newState !== null) {
    newFilters = { ...state, ...newState };
  }
  return newFilters;
};

const isObject = (obj) => {
  return obj != null && obj.constructor.name === "Object";
};

const FilterForm = ({
  buttonLabel,
  classes,
  fields,
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  errors,
  setFieldError,
  resetForm,
}) => {
  const disabled = empty(values);
  const t = useTranslator();
  const { isAmPm } = useLocale();

  const getFormField = (field) => {
    const commonProps = {
      name: field.name,
      label: (
        <FormLabel
          label={<Translate needle={field.label} />}
          tooltip={{ fontSize: "inherit", ...field.tooltip }}
        />
      ),
      value: field.disabled ? generateIntialValue(field) : values[field.name],
      onChange: (event) => {
        handleChange(event);
        field.onChange && field.onChange(event.target.value);
      },
      onBlur: handleBlur,
      fullWidth: true,
      classes: {
        root: classes.formControlRoot,
      },
      placeholder: field.placeholder ? t({ needle: field.placeholder }) : "",
      disabled: field.disabled,
    };
    const getDateRange = (from, to) => {
      return (
        <>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className={classes.formFieldWrapper}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                margin="none"
                {...commonProps}
                label={
                  <FormLabel
                    tooltip={{ fontSize: "inherit", ...field.tooltip }}
                    label={
                      t({ needle: field.label }) +
                      " " +
                      t({ needle: "label.filters.from" })
                    }
                  />
                }
                value={values[field.name].from}
                maxDate={values[field.name].to || undefined}
                onChange={(date) => {
                  const { to } = values[field.name] || {};
                  setFieldValue(
                    field.name,
                    {
                      from: date ? moment(date).startOf("day").valueOf() : null,
                      to,
                      period: 6,
                    },
                    false
                  );
                }}
                onError={(error, value) => {
                  if (error !== errors[field.name + "from"]) {
                    setFieldError(field.name + "from", error);
                  }
                }}
                onBlur={() => {}}
                invalidDateMessage={t({ needle: "label.filters.invalidDate" })}
                minDateMessage={t({ needle: "label.filters.invalidMinDate" })}
                maxDateMessage={t({ needle: "label.filters.invalidMaxDate" })}
                error={Boolean(errors[field.name + "from"])}
                helperText={errors[field.name + "from"]}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className={classes.formFieldWrapper}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                margin="none"
                {...commonProps}
                label={
                  <FormLabel
                    tooltip={{ fontSize: "inherit", ...field.tooltip }}
                    label={
                      t({ needle: field.label }) +
                      " " +
                      t({ needle: "label.filters.to" })
                    }
                  />
                }
                value={values[field.name].to}
                minDate={values[field.name].from || undefined}
                onChange={(date) => {
                  const { from } = values[field.name] || {};
                  setFieldValue(field.name, {
                    from,
                    to: date ? moment(date).endOf("day").valueOf() : null,
                    period: 6,
                  });
                }}
                onError={(error, value) => {
                  if (error !== errors[field.name + "to"]) {
                    setFieldError(field.name + "to", error);
                  }
                }}
                error={Boolean(errors[field.name + "to"])}
                onBlur={() => {}}
                invalidDateMessage={t({ needle: "label.filters.invalidDate" })}
                minDateMessage={t({ needle: "label.filters.invalidMinDate" })}
                maxDateMessage={t({ needle: "label.filters.invalidMaxDate" })}
                helperText={errors[field.name + "to"]}
              />
            </Box>
          </Grid>
        </>
      );
    };

    const getDateTimeRange = () => {
      return (
        <>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className={classes.formFieldWrapper}>
              <KeyboardDateTimePicker
                clearable
                ampm={isAmPm}
                views={["date", "hours", "minutes"]}
                variant="inline"
                margin="none"
                {...commonProps}
                label={
                  <FormLabel
                    tooltip={{ fontSize: "inherit", ...field.tooltip }}
                    label={
                      t({ needle: field.label }) +
                      " " +
                      t({ needle: "label.filters.from" })
                    }
                  />
                }
                value={values[field.name].from}
                maxDate={values[field.name].to || undefined}
                onChange={(date) => {
                  const { to } = values[field.name] || {};
                  setFieldValue(field.name, {
                    from: date ? moment(date).valueOf() : null,
                    to,
                    period: 6,
                  });
                }}
                onError={(error, value) => {
                  if (error !== errors[field.name + "from"]) {
                    setFieldError(field.name + "from", error);
                  }
                }}
                onBlur={() => {}}
                invalidDateMessage={t({ needle: "label.filters.invalidDate" })}
                minDateMessage={t({ needle: "label.filters.invalidMinDate" })}
                maxDateMessage={t({ needle: "label.filters.invalidMaxDate" })}
                error={Boolean(errors[field.name + "from"])}
                helperText={errors[field.name + "from"]}
                className={classes.font}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className={classes.formFieldWrapper}>
              <KeyboardDateTimePicker
                clearable
                ampm={isAmPm}
                views={["date", "hours", "minutes"]}
                variant="inline"
                margin="none"
                {...commonProps}
                label={
                  <FormLabel
                    tooltip={{ fontSize: "inherit", ...field.tooltip }}
                    label={
                      t({ needle: field.label }) +
                      " " +
                      t({ needle: "label.filters.to" })
                    }
                  />
                }
                value={values[field.name].to}
                minDate={moment(values[field.name].from).toDate() || undefined}
                onChange={(date) => {
                  const { from } = values[field.name] || {};
                  setFieldValue(field.name, {
                    from,
                    to: date ? moment(date).valueOf() : null,
                    period: 6,
                  });
                }}
                onError={(error, value) => {
                  if (error !== errors[field.name + "to"]) {
                    setFieldError(field.name + "to", error);
                  }
                }}
                error={Boolean(errors[field.name + "to"])}
                onBlur={() => {}}
                invalidDateMessage={t({ needle: "label.filters.invalidDate" })}
                minDateMessage={t({ needle: "label.filters.invalidMinDate" })}
                maxDateMessage={t({ needle: "label.filters.invalidMaxDate" })}
                helperText={errors[field.name + "to"]}
                className={classes.font}
              />
            </Box>
          </Grid>
        </>
      );
    };

    switch (field.type) {
      case "conditional": {
        return (
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <Box className={classes.formFieldWrapper}>
              <FormControl className={classes.formControlRoot}>
                <InputLabel htmlFor={"condition-field-" + field.name}>
                  {commonProps.label}
                </InputLabel>
                <Box mt={"6px"} display={"flex"}>
                  <Box width={"50%"}>
                    <Select
                      {...commonProps}
                      label={null}
                      name={field.name + CONDITIONAL_KEY}
                      displayEmpty={true}
                      onChange={(event) => {
                        handleChange(event);
                        if (event.target.value === "") {
                          setFieldValue(field.name, "");
                        }
                      }}
                      value={values[field.name + CONDITIONAL_KEY] || ""}
                    >
                      <MenuItem value={""}>
                        <span className={"placeholder"}>
                          <Translate needle={"label.filters.selectCondition"} />
                        </span>
                      </MenuItem>
                      {conditions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          <Translate needle={option.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box width={"50%"} ml={1}>
                    <Input
                      id={"condition-field-" + field.name}
                      {...commonProps}
                      type={"number"}
                      label={null}
                      disabled={
                        values[field.name + CONDITIONAL_KEY] == null ||
                        values[field.name + CONDITIONAL_KEY] === ""
                      }
                      placeholder={t({ needle: "label.filters.enterValue" })}
                    />
                  </Box>
                </Box>
              </FormControl>
            </Box>
          </Grid>
        );
      }
      case "date":
        return (
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            {...commonProps}
            onChange={(date) => {
              const dateValue = date ? moment(date).startOf("day") : false;
              const currentTime = moment().hour().valueOf();
              setFieldValue(
                field.name,
                dateValue
                  ? moment
                      .utc(dateValue.add(moment.duration(currentTime, "hours")))
                      .valueOf()
                  : null
              );
            }}
            onBlur={() => {}}
          />
        );
      case "singleDateRange":
        return (
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            {...commonProps}
            value={
              commonProps && commonProps.value?.from
                ? moment(commonProps.value.from).unix() * 1000
                : isNaN(commonProps.value?.from)
                ? NaN
                : null
            }
            onChange={(date) => {
              if (date) {
                const dateTo = moment(date).endOf("day").unix() * 1000;
                const dateFrom = moment(date).startOf("day").unix() * 1000;
                setFieldValue(field.name, { from: dateFrom, to: dateTo });
              } else {
                setFieldValue(field.name, { from: null, to: null });
              }
            }}
            onBlur={() => {}}
          />
        );
      case "time":
        return (
          <KeyboardTimePicker
            disableToolbar
            variant="inline"
            margin="none"
            {...commonProps}
            onChange={(date) => {
              setFieldValue(field.name, date ? moment(date).valueOf() : null);
            }}
            onBlur={() => {}}
            value={null}
          />
        );
      case "datetime":
        return (
          <KeyboardDateTimePicker
            clearable
            ampm={isAmPm}
            views={["date", "hours", "minutes"]}
            variant="inline"
            margin="none"
            {...commonProps}
            value={values[field.name] ? values[field.name] : null}
            onChange={(date) => {
              setFieldValue(field.name, date ? moment(date).valueOf() : null);
            }}
            onBlur={() => {}}
          />
        );
      case "daterange": {
        return getDateRange();
      }
      case "datetimerange": {
        return getDateTimeRange();
      }
      case "period": {
        const handlePeriodChange = (event) => {
          const val = event.target.value;
          switch (val) {
            case "1":
              return setFieldValue(field.name, {
                from: moment().startOf("day").valueOf(),
                to: moment().endOf("day").valueOf(),
                period: 1,
              });
            case "2":
              return setFieldValue(field.name, {
                from: moment().subtract(1, "days").startOf("day").valueOf(),
                to: moment().subtract(1, "days").endOf("day").valueOf(),
                period: 2,
              });
            case "3":
              return setFieldValue(field.name, {
                from: moment().subtract(6, "days").startOf("day").valueOf(),
                to: moment().endOf("day").valueOf(),
                period: 3,
              });
            case "4":
              return setFieldValue(field.name, {
                from: moment().subtract(30, "days").startOf("day").valueOf(),
                to: moment().endOf("day").valueOf(),
                period: 4,
              });
            case "5":
              return setFieldValue(field.name, {
                from: moment().startOf("month").valueOf(),
                to: moment().endOf("month").valueOf(),
                period: 5,
              });
            case "6":
              return setFieldValue(field.name, {
                from: null,
                to: null,
                period: 6,
              });
            default:
              return "";
          }
        };
        if (values[field.name].period === "") {
          setFieldValue(field.name, {
            from: moment().subtract(6, "days").startOf("day").valueOf(),
            to: moment().endOf("day").valueOf(),
            period: 3,
          });
        }
        return (
          <>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Box className={classes.formFieldWrapper}>
                <TextField
                  select
                  SelectProps={{ displayEmpty: true }}
                  fullWidth={true}
                  classes={{
                    root: classes.formControlRoot,
                  }}
                  label={commonProps.label}
                  onChange={handlePeriodChange}
                  defaultValue={""}
                  value={values[field.name].period}
                >
                  <MenuItem value={"1"} selected={true}>
                    <Translate needle={"label.filters.period.today"} />
                  </MenuItem>
                  <MenuItem value={"2"}>
                    <Translate needle={"label.filters.period.yesterday"} />
                  </MenuItem>
                  <MenuItem value={"3"}>
                    <Translate needle={"label.filters.period.last7Days"} />
                  </MenuItem>
                  <MenuItem value={"4"}>
                    <Translate needle={"label.filters.period.last30Days"} />
                  </MenuItem>
                  <MenuItem value={"5"}>
                    <Translate needle={"label.filters.period.thisMonth"} />
                  </MenuItem>
                  <MenuItem value={"6"}>
                    <Translate needle={"label.filters.period.specific"} />
                  </MenuItem>
                </TextField>
              </Box>
            </Grid>
            {getDateRange()}
          </>
        );
      }
      case "checkbox": {
        return (
          <Box>
            <FormControlLabel
              className={classes.checkboxField}
              control={
                <Checkbox
                  color="primary"
                  checked={values[field.name]}
                  onChange={(value) => {
                    setFieldValue(field.name, !values[field.name]);
                    field.onChange && field.onChange(!values[field.name]);
                  }}
                />
              }
              label={commonProps.label}
            />
          </Box>
        );
      }
      case "select": {
        return (
          <Autocomplete
            multiple={field.multiple}
            disabled={field.disabled}
            onChange={(event, value) => {
              let v = field.multiple
                ? value.map((it) => (it["value"] != null ? it.value : it))
                : value["value"] != null
                ? value["value"]
                : value;
              setFieldValue(field.name, v);
              field.onChange && field.onChange(v, values, setFieldValue);
            }}
            value={
              field.multiple
                ? field.options.filter(
                    (it) =>
                      commonProps.value &&
                      commonProps.value.includes(
                        it["value"] != null ? it["value"] : it
                      )
                  )
                : getSingleValue(field, commonProps)
            }
            options={field.options}
            getOptionLabel={(option) =>
              option["label"] ? t({ needle: option.label }) : option
            }
            renderInput={(params) => (
              <TextField
                {...params}
                {...commonProps}
                onChange={() => {}}
                placeholder={
                  commonProps.value && commonProps.value.length === 0
                    ? commonProps.placeholder
                    : null
                }
              />
            )}
          />
        );
      }
      // eslint-disable-next-line
      default:
        return (
          <TextField
            select={field.type === "select"}
            {...commonProps}
            type={field.type || "text"}
            SelectProps={{ displayEmpty: true }}
          >
            {commonProps.placeholder !== "" && (
              <MenuItem value="">
                <span className="placeholder">{commonProps.placeholder}</span>
              </MenuItem>
            )}
            {field.options
              ? field.options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    <Translate needle={option.label} />
                  </MenuItem>
                ))
              : null}
          </TextField>
        );
    }
  };

  const getSingleValue = (field, commonProps) => {
    const obj = field.options.find(
      (it) =>
        commonProps.value != null &&
        commonProps.value === (it["value"] != null ? it["value"] : it)
    );
    return obj ? obj : "";
  };

  return (
    <Box width={1}>
      <Form>
        <Grid container spacing={2}>
          <Grid item sm={8} md={9} lg={10} xs={12}>
            <Grid container spacing={2}>
              {fields
                .filter((it) => {
                  if (it.hidden != null && typeof it.hidden === "function") {
                    return !it.hidden(values || {});
                  }
                  return true;
                })
                .map((field) => (
                  <React.Fragment key={field.name}>
                    {field.newLine ? (
                      <div style={{ display: "block", width: "100%" }} />
                    ) : null}
                    {field.type === "daterange" ||
                    field.type === "datetimerange" ||
                    field.type === "period" ||
                    field.type === "conditional" ? (
                      getFormField(field)
                    ) : (
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Box className={classes.formFieldWrapper}>
                          {getFormField(field)}
                        </Box>
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
            </Grid>
          </Grid>
          <Grid
            container
            item
            sm={4}
            md={3}
            lg={2}
            xs={12}
            alignItems={"flex-end"}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  disabled={disabled}
                  fullWidth
                  type="reset"
                  variant={"outlined"}
                  onClick={() => {
                    resetForm({ values: "" });
                  }}
                >
                  <Translate needle={"button.clear"} />
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  disabled={disabled}
                  fullWidth
                  type="submit"
                  color="primary"
                >
                  <Translate needle={buttonLabel} />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </Box>
  );
};

const isDateTime = (type) => {
  return ["date", "time", "datetime"].includes(type);
};
const getFomatDate = (type) => {
  switch (type) {
    case "date":
      return "L";
    case "datetime":
      return "L LT";
    case "time":
      return "LT";
    case "singleDateRange":
      return "L";
    default: {
      throw Error("Unexpected date type");
    }
  }
};

const CollapsibleFilter = ({
  children,
  fields,
  filters,
  setFieldValue,
  submitForm,
  classes,
  title,
}) => {
  const visibleFilters =
    filters == null
      ? []
      : Object.entries(filters).filter(([key, value]) => {
          const fieldDefinition = fields.find((field) => field.name === key);
          if (fieldDefinition === undefined) return false;
          if (fieldDefinition.disabled) return false;
          if (key.endsWith(CONDITIONAL_KEY)) return false;
          if (value == null) return false;
          if (Array.isArray(value)) return value.length > 0;
          if (
            fieldDefinition.type === "daterange" ||
            fieldDefinition.type === "datetimerange" ||
            fieldDefinition.type === "period" ||
            fieldDefinition.type === "singleDateRange"
          ) {
            return value.from != null || value.to != null;
          }
          if (fieldDefinition.hideBadge) return false;
          if (fieldDefinition.type === "checkbox") return value;
          if (
            fieldDefinition.hidden != null &&
            typeof fieldDefinition.hidden === "function" &&
            fieldDefinition.hidden(filters)
          ) {
            return false;
          }
          return value !== "";
        });
  const filtersCount = visibleFilters.length;
  const t = useTranslator();

  const getDateRangeFilter = (from, to) => {
    if (from && to) {
      return (
        t({ needle: "label.filters.from" }) +
        moment(from).format("L") +
        " " +
        t({ needle: "label.filters.to" }) +
        moment(to).format("L")
      );
    }
    if (from && !to) {
      return t({ needle: "label.filters.from" }) + moment(from).format("L");
    }
    if (!from && to) {
      return t({ needle: "label.filters.to" }) + moment(to).format("L");
    }
  };

  const getDateTimeRangeFilter = (from, to) => {
    if (from && to) {
      return (
        t({ needle: "label.filters.from" }) +
        moment(from).format("L LT") +
        " " +
        t({ needle: "label.filters.to" }) +
        moment(to).format("L LT")
      );
    }
    if (from && !to) {
      return t({ needle: "label.filters.from" }) + moment(from).format("L LT");
    }
    if (!from && to) {
      return t({ needle: "label.filters.to" }) + moment(to).format("L LT");
    }
  };

  const getActiveFilters = () => {
    return visibleFilters.map(([key, v]) => {
      const field = fields.find((it) => it.name === key);
      const operation =
        field.type === "conditional" && filters[key + CONDITIONAL_KEY]
          ? conditions.find((op) => op.value === filters[key + CONDITIONAL_KEY])
          : null;
      return (
        <Button
          key={key}
          classes={{
            root: classes.buttonRoot,
            endIcon: classes.endIcon,
          }}
          endIcon={
            <Close
              onClick={async () => {
                let value = "";
                if (isDateTime(field.type)) {
                  value = null;
                }
                if (field.type === "select" && field.multiple) {
                  value = [];
                }
                if (field.type === "checkbox") {
                  value = false;
                }
                if (
                  field.type === "period" ||
                  field.type === "daterange" ||
                  field.type === "singleDateRange" ||
                  field.type === "datetimerange"
                ) {
                  value = { from: null, to: null, period: "" };
                }
                await setFieldValue(key, value);
                submitForm();
              }}
            />
          }
          color={"secondary"}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            flexGrow={1}
            alignItems={"flex-start"}
            minWidth={100}
          >
            <Typography variant={"subtitle2"}>
              <Translate needle={field.label} />
            </Typography>
            <Typography variant={"subtitle1"}>
              {operation ? (
                <span>
                  <Translate needle={operation.label} />
                  &nbsp;
                </span>
              ) : null}
              {Array.isArray(v)
                ? getTranslatedLabels(v, field["options"], ", ", t)
                : isDateTime(field.type)
                ? moment(v).format(getFomatDate(field.type))
                : field.type === "singleDateRange"
                ? moment(v.from).format(getFomatDate(field.type))
                : field.type === "select"
                ? getTranslatedLabels(v, field["options"], ", ", t)
                : field.type === "daterange" || field.type === "period"
                ? getDateRangeFilter(v.from, v.to)
                : field.type === "datetimerange"
                ? getDateTimeRangeFilter(v.from, v.to)
                : field.type === "checkbox"
                ? t({ needle: "label.yes" })
                : v}
            </Typography>
          </Box>
        </Button>
      );
    });
  };

  return (
    <>
      <ExpansionPanel
        defaultExpanded={true}
        elevation={0}
        className={classes.expansionPanel}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box flexGrow={1}>
            <Typography className={classes.panelTitle}>
              <Translate needle={title} />
            </Typography>
          </Box>
          {filtersCount > 0 && (
            <Box
              className={classes.filterBadges}
              bgcolor={"secondary.main"}
              borderRadius={6}
              minWidth={"40px"}
              align={"center"}
              color={"white"}
            >
              {filtersCount}
            </Box>
          )}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.panelDetails}>
          {children}
        </ExpansionPanelDetails>
      </ExpansionPanel>
      {filtersCount > 0 && (
        <Box display={"flex"} mt={2}>
          {getActiveFilters()}
        </Box>
      )}
    </>
  );
};

const StandardFilter = ({ children }) => {
  return children;
};

const empty = (filters) => {
  return (
    filters == null ||
    Object.entries(filters).filter(([key, it]) => {
      if (key.endsWith(CONDITIONAL_KEY)) return false;
      if (it == null) return false;
      if (Array.isArray(it)) return it.length > 0;
      if (isObject(it)) {
        let objToCheck = { ...it };
        if (it.period) delete objToCheck.period;
        return !empty(objToCheck);
      }
      if (typeof it === "boolean") {
        return it;
      }
      return it !== "";
    }).length === 0
  );
};
const generateIntialValue = (it) => {
  if (it.initialValue != null) return it.initialValue;
  if (it.type === "select" && it.multiple) {
    return [];
  }
  if (it.type === "date" || it.type === "datetime" || it.type === "time") {
    return null;
  }
  if (
    it.type === "daterange" ||
    it.type === "singleDateRange" ||
    it.type === "datetimerange"
  ) {
    return { from: null, to: null };
  }
  if (it.type === "period") {
    return {
      from: null,
      to: null,
      period: "",
    };
  }
  if (it.type === "checkbox") {
    return it.value !== undefined ? it.value : false;
  }
  return "";
};
const generateInitialValues = (fields, filters) => {
  return fields
    .filter((it) => {
      if (it.hidden != null && typeof it.hidden === "function") {
        return !it.hidden(filters || {});
      }
      return true;
    })
    .reduce((accumulator, it) => {
      if (filters && filters[it.name + CONDITIONAL_KEY]) {
        accumulator[it.name + CONDITIONAL_KEY] =
          filters[it.name + CONDITIONAL_KEY];
      }
      if (filters && filters[it.name]) {
        accumulator[it.name] = filters[it.name];
      } else {
        accumulator[it.name] = generateIntialValue(it);
      }
      return accumulator;
    }, {});
};

const Filters = ({
  filtersKey,
  isLoading,
  type = "filter",
  fields = [],
  onSearch,
  onReset,
  collapsible = false,
}) => {
  const classes = useStyle();
  const [filters, setFilters] = React.useReducer(reducer, null);

  const search = React.useCallback(
    debounce((values) => {
      onSearch && onSearch(values);
    }, 400),
    [onSearch]
  );
  const reset = React.useCallback(
    debounce(() => {
      onReset && onReset();
    }, 400),
    [onReset]
  );

  React.useEffect(() => {
    if (isLoading) return;
    if (empty(filters)) reset();
    else {
      const searchTerms = {};
      Object.entries(filters).forEach(([k, v]) => {
        if (!k.endsWith(CONDITIONAL_KEY)) {
          searchTerms[k] =
            filters[k + CONDITIONAL_KEY] && v
              ? {
                  condition: filters[k + CONDITIONAL_KEY],
                  value: v,
                }
              : v;
        }
      });
      search(searchTerms);
    }
    //eslint-disable-next-line
  }, [filters, isLoading]);

  const initialValues = generateInitialValues(fields, filters);

  const handleReset = () => {
    putSavedFilters(filtersKey, null);
    setFilters(null);
    //onReset();
  };

  const handleSubmit = (values) => {
    putSavedFilters(filtersKey, values);
    setFilters(values);
  };

  const savedFilters = getSavedFilters(filtersKey);
  if (!empty(savedFilters) && filters == null) {
    handleSubmit(savedFilters);
  }
  if (empty(savedFilters) && filters != null) {
    handleReset();
  }

  const title =
    type === "search" ? "label.filters.search" : "label.filters.filter";
  const buttonLabel = type === "search" ? "button.search" : "button.filter";

  const props = {
    title,
    classes,
    fields,
    filters,
  };

  const form = (formikProps) => (
    <FilterForm
      {...formikProps}
      classes={classes}
      buttonLabel={buttonLabel}
      fields={fields}
    />
  );

  const Wrapper = collapsible ? CollapsibleFilter : StandardFilter;

  if (isLoading)
    return (
      <Wrapper {...props} filters={[]}>
        <Grid container spacing={2}>
          <Grid container item spacing={2} sm={8} md={9} lg={10}>
            {Array(6)
              .fill()
              .map((it, index) => (
                <Grid item xs={12} sm={4} key={index}>
                  <Skeleton variant={"text"} width={"40%"} />
                  <Skeleton variant={"rect"} width={"80%"} height={30} />
                </Grid>
              ))}
          </Grid>
          <Grid
            container
            item
            spacing={2}
            sm={4}
            md={3}
            lg={2}
            alignItems={"flex-end"}
          >
            <Grid item xs={6}>
              <Skeleton variant={"rect"} height={30} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant={"rect"} height={30} />
            </Grid>
          </Grid>
        </Grid>
      </Wrapper>
    );

  if (
    fields.filter((it) => {
      if (it.hidden != null && typeof it.hidden === "function") {
        return !it.hidden(filters || {});
      }
      return true;
    }).length === 0
  ) {
    return (
      <Wrapper {...props} filters={[]}>
        <NoDataToShow />
      </Wrapper>
    );
  }

  return (
    <div className={classes.root}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onReset={handleReset}
      >
        {(formikProps) => {
          return (
            <Wrapper {...formikProps} {...props}>
              {form(formikProps)}
            </Wrapper>
          );
        }}
      </Formik>
    </div>
  );
};

export default Filters;
