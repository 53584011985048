import axios from "axios";

class KeyAccessManagementService {
  async loadKeyAccessList(contractId, brand, partner, registrationLevelId) {
    const response = await axios.get(
      `/gov/api/rest/v1/players/${contractId}/lock_unlock/access_key?brandCode=${brand}&partnerCode=${partner}&registrationLevelId=${registrationLevelId}`
    );
    if (response) {
      return response.data;
    }
    throw Error("Invalid response for individual get key access list");
  }

  async resetKeyAccess(
    contractId,
    userId,
    brand,
    identifier,
    keyType,
    partner
  ) {
    const response = await axios.put(
      `/gov/api/rest/v1/players/${contractId}/reset_password`,
      {
        brandCode: brand,
        id: userId,
        identifier: identifier,
        keyType: keyType,
        partnerCode: partner,
      }
    );
    if (response) {
      return response.status;
    }
    throw Error("Invalid response for individual reset key access");
  }

  async lockUnlockKeyAccess(
    contractId,
    brand,
    identifier,
    keyType,
    keyName,
    partner,
    toLock,
    unlockDate
  ) {
    const response = await axios.put(
      `/gov/api/rest/v1/players/${contractId}/lock_unlock`,
      {
        brandCode: brand,
        id: contractId,
        lockIdentifier: identifier,
        keyType: keyType,
        keyName: keyName,
        partnerCode: partner,
        toLock: toLock,
        lockDate: unlockDate,
      }
    );
    if (response) {
      return response.status;
    }
    throw Error("Invalid response for individual lock/unlock key access");
  }

  async editKeyAccess(
    contractId,
    brand,
    identifier,
    keyType,
    keyName,
    partner,
    confirmPassword,
    newPassword
  ) {
    const response = await axios.put(
      `/gov/api/rest/v1/accounts/${contractId}/change_password/key_type/${keyType}`,
      {
        brandCode: brand,
        confirmPassword: confirmPassword,
        id: contractId,
        identifier: identifier,
        keyTypeValue: keyName,
        partnerCode: partner,
        newPassword: newPassword,
      }
    );
    if (response) {
      return response.status;
    }
    throw Error("Invalid response for individual edit key access");
  }
}

const keyAccessManagementService = new KeyAccessManagementService();
export default keyAccessManagementService;
